import { graphql } from "gatsby"
import * as React from "react"
import BlogList from "../components/BlogList"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

export default function BlogPage({ data }) {
  return (
    <Layout learnMore>
      <Seo title="Blog - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO" />
      <BlogList posts={data.allMdx.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query blog {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          cover {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                pngOptions: { quality: 80 }
                webpOptions: { quality: 70 }
                width: 700
              )
            }
          }
          author
          date
          excerpt
          slug
          title
        }
      }
    }
  }
`
