import styled from "@emotion/styled"
import React from "react"
import BlogPostDisplay from "./BlogPostDisplay"

const Section = styled.section`
  display: grid;
    grid-template-columns: 1fr;
  gap: 32px;
    margin: 64px auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
    
  }

  @media (min-width: 1640px) {
    gap: 64px 96px;
    padding: 0;
  }

`
export default function BlogList({ posts }) {
  return (
    <Section className="container">
      {posts.map((post, i) => (
        <BlogPostDisplay
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          author={post.frontmatter.author}
          cover={post.frontmatter.cover}
          excerpt={post.frontmatter.excerpt}
          slug={post.frontmatter.slug}
          key={i}
        />
      ))}
    </Section>
  )
}
