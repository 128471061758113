import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { BlueBtn } from "./layout/styledComponents"

const Box = styled.div`

  .gatsby-image-wrapper {
    height: 350px;
  }

  @media (min-width: 1024px) {
  .gatsby-image-wrapper {

    height: 465px;

  }
  }
`

export default function PostDisplay({
  title,
  excerpt,
  date,
  author,
  cover,
  slug,
}) {
  return (
    <Box>
      <GatsbyImage image={getImage(cover)} alt={title} />
      <h3>{title}</h3>
      <p>
        {author ? author : "Pikes Peak"} &bull; {date}
      </p>
      <p>{excerpt}</p>
      <Link to={`/blog/${slug}`}>
        <BlueBtn>READ MORE</BlueBtn>
      </Link>
    </Box>
  )
}
